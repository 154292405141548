//注册element-ui 组件库
import Vue from 'vue'
//导入
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//注册
Vue.use(ElementUI);
//注册 myplusin
import myPlugin from './myPlugin';
Vue.use(myPlugin);

