import {
  get,
  post,
  del,
  put,
  setToken
} from "../util/request"

export default {
  install: function (Vue) {
    //执行该方法，将游览器缓存的token 保存到axios的请求头中
    setToken();
    //给vue 混路
    Vue.mixin({
      methods: {
        $get(url, params) {
          return get(url, params);
        },
        $post(url, params) {
          return post(url, params);
        },
        $put(url, params) {
          return put(url, params);
        },
        $del(url, params) {
          return del(url, params);
        },
        $setToken() {
          //执行该方法，将游览器缓存的token 保存到axios的请求头中
          setToken();
        },
        //成功消息框
        $msg_ok(message, duration = 3000) {
          this.$message({
            showClose: true,
            message,
            type: "success",
            duration
          });
        },
        //警告消息框
        $msg_warn(message, duration = 3000) {
          this.$message({
            showClose: true,
            message,
            type: "warning",
            duration
          });
        },
        //错误消息框
        $msg_err(message, duration = 3000) {
          this.$message({
            showClose: true,
            message,
            type: "error",
            duration
          });
        }
      }
    })
  }
}