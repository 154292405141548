//请求相关的方法
import axios from 'axios'

import nProgress from 'nprogress';
import 'nprogress/nprogress.css';
import {
  Message
} from "element-ui"; //这里用的element-ui中的Message消息提示插件

const openAiKey="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";

const ip="monitor-api.mymhp.cn";
let port = "";
let baseURL = 'https://' + ip + ":" + port;
let webSocketUrl = "wss://" + ip + ":" + port + "/socket/connect?clientId=";



// const ip="localhost";
// let port = 7777;
// let baseURL = 'http://' + ip + ":" + port;
// let webSocketUrl = "ws://" + ip + ":" + port + "/socket/connect?clientId=";


// const ip = "222.71.181.138";
// let port = "7777";
// let baseURL = 'http://' + ip + ":" + port;
// let webSocketUrl = "ws://" + ip + ":" + port + "/socket/connect?clientId=";



//初始化111
var instance = axios.create({
  
  //请求地址
  baseURL: baseURL,
  timeout: 1000000,
  headers: {
    'Content-type': 'application/json;chatset=utf-8'
  }
});
//get 请求方法
let get = function (url, params) {
  return instance.get(url, {
    params
  });
}
//post 请求方法
let post = function (url, params) {
  var data = instance.post(url, params);
  return data
}
//del 请求方法
let del = function (url, params) {
  return instance.delete(url, {
    params
  });
}
//put 请求方法
let put = function (url, params) {
  return instance.put(url, params);
}

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  //开启进度条
  nProgress.start();
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  //关闭进度条
  nProgress.done();
  // 对请求错误做些什么
  return Promise.reject(error);
});


//拦截服务端响应的信息,同意验证
instance.interceptors.response.use(response => {

  nProgress.done();

  //HTTP响应码是200，后端自己定义了500，在RespBean中，定义为业务逻辑错误
  if (response.data.statusCode && response.data.statusCode == 200) {
    //返回方法调用的哪里，拿到的就是服务端返回的数据

    if (response.data.data == true) {
      Message.info({
        message: "操作成功"
      });
    }

    return response.data;
  } else if (response.data.statusCode && response.data.statusCode == 306 || response.data.statusCode == 304) {
    //账号在其他地方登录，token过期，返回登录页
    let API_HOST = window.location.protocol + "//" + window.location.host + "/"
    window.location.href = API_HOST;

  } else if (response.data.statusCode && response.data.statusCode == 302) {
    //参数错误
    Message.error({
      message: response.data.data[0].field + ":" + response.data.data[0].defaultMessage
    });
  } else {
    Message.error({
      message: response.data.message
    });
  }
  return response.data;

}, err => {
  //如果HTTP响应码是400,500等就会进入这个err函数中
  //如果服务器没启动或者是路径错误进入此判断中
  nProgress.done();
  try {
    if (err.response.status == 504) {
      Message.error({
        message: "服务器未启动"
      });
    } else if (err.response.status == 404) { //表示权限不足
      Message.error({
        message: "url路径不存在"
      });
    } else if (err.response.status == 403) { //表示权限不足
      Message.error({
        message: "权限不足，请联系管理员"
      });
    } else if (err.response.status == 401) { //表示未登录
      Message.error({
        message: err.response.data.msg //服务器返回来的信息
      });
    } else {
      if (err.response.data.msg) {
        Message.error({
          message: err.response.data.msg
        });
      } else {
        Message.error({
          message: "未知错误"
        });
      }
    }
  } catch (error) {
    Message.error({
      message: "服务器未启动"
    });
    return Promise.reject(error);
  }


})
//将token信息保存到请求头
let setToken = function () {
  instance.defaults.headers.common['token'] = localStorage.getItem('token');
}

//到出方法
export {
  get,
  post,
  del,
  put,
  setToken,
  webSocketUrl,
  ip,
  openAiKey,
  baseURL
}