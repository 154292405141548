<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import requestApi from "@/api/system.js";

export default {
  onload() {

  }, data() {
    return {
      form:{}
    }
  },
  created() {
    this.isPageAllColor();
   
  },
   methods: {
    isPageAllColor() {
      let params = {};
      this.$get(requestApi.isPageAllColor, params).then((res) => {
        if (res.success && res.statusCode == 200) {
          let isPageAllColor = res.data;
          if(isPageAllColor){
            document.documentElement.style.webkitFilter = "grayscale(100%)";
            document.documentElement.style.mozFilter = "grayscale(100%)";
            document.documentElement.style.msFilter = "grayscale(100%)";
            document.documentElement.style.oFilter = "grayscale(100%)";
            document.documentElement.style.filter = "grayscale(100%)";
            document.documentElement.style.webkitFilter = "gray";
            document.documentElement.style.filter = "gray";
            document.documentElement.style.webkitFilter = "progid:dximagetransform.microsoft.basicimage(grayscale=1)";
            document.documentElement.style.filter = "progid:dximagetransform.microsoft.basicimage(grayscale=1)";
          }
          

        }
      });
    }
  }

}

</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
  text-decoration: none;
  font-family: 'Microsoft YaHei';
  
}

.flex {
  display: flex;
}

.j-c {
  justify-content: center;
}

.j-s {
  justify-content: space-between;
}

.a-c {
  align-items: center;
}


</style>


