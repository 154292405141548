export default{
  //设置为私有命名空间
  namespaced:true,
  state:{
    theme:[
      {
        name:"蓝色主题",
        color: '#101f30'
      },
      {
        name:"灰色主题",
        color: '#2b333e'
      },
      {
        name:"红色主题",
        color: '#7c1823'
      },
      {
        name:"黑色主题",
        color: '#000000'
      },
      {
        name:"绿色主题",
        color: '#15231b'
      }
    ]

  },

}