// 注：useComponents 需放最上面，优先注册懒加载组件


import '@c/k-form-design/packages/core/useComponents'
import KFormDesign from '@c/k-form-design/packages/use.js'
import '@c/k-form-design/lib/k-form-design.css'

Vue.use(KFormDesign)


import Vue from 'vue'
import App from './App.vue'
//导入路由
import router from './router'
//导入状态管理
import store from './store'
//导入插件
import './plugin'

import Clipboard from 'v-clipboard'

import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts;

Vue.prototype.$websocket = null;

Vue.config.productionTip = false
Vue.use(Clipboard)
//Vue.prototype.$getUser = userInfo.userInfo;

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor);

import JsonViewer from 'vue-json-viewer';
Vue.use(JsonViewer);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
