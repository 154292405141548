import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    //路径别名+文件
    component: () => import('@v/Login.vue')
  },
  {
    path: '/login',
    //路径别名+文件
    component: () => import('@v/Login.vue')
  }, {
    path: '/loginOut',
    //路径别名+文件
    component: () => import('@v/Login.vue')
  },
  {
    path: '/layout',
    component: () => import('@v/Layout.vue'),
    //嵌套路由
    children: [
      {
        path: '/home',
        component: () => import('@v/Home.vue')
      },
      {
        path: '/welcome',
        component: () => import('@v/Welcome.vue')
      },
      {
        path: '/role',
        component: () => import('@v/system/role/Role.vue')
      },
      {
        path: '/menu',
        component: () => import('@v/system/menu/Menu.vue')
      },
      {
        path: '/user',
        component: () => import('@v/system/user/User.vue')
      },
      {
        path: '/release',
        component: () => import('@v/system/release/release.vue')
      },
      {
        path: '/base',
        component: () => import('@v/system/setting/Base.vue')
      },
      {
        path: '/monitor',
        component: () => import('@v/system/monitor/Monitor.vue')
      },
      {
        path: '/push',
        component: () => import('@v/system/push/Push.vue')
      },
      {
        path: '/flow',
        component: () => import('@v/system/flow/FlowView.vue')
      },
      {
        path: '/picture-operate',
        component: () => import('@v/system/operation/PictureOperate.vue')
      }, 
      
      {
        path: '/form',
        component: () => import('@v/system/flow/FormGenerator.vue')
      },
      {
        path: '/tools',
        component: () => import('@v/system/tool/Tools.vue')
      },
      {
        path: '/ai',
        component: () => import('@v/system/tool/Ai.vue')
      },
      {
        path: '/haproxy',
        component: () => import('@v/system/haproxy/Haproxy.vue')
      },
      {
        path: '/jinglong',
        component: () => import('@v/system/iot/jinglong.vue')
      },
      {
        path: '/mhp-authorize',
        component: () => import('@v/system/authorize/MhpAuthorize.vue')
      },
      {
        path: '/mhpRequestRecord',
        component: () => import('@v/system/mhp/MhpRequestRecord.vue')
      },
      {
        path: '/iot',
        component: () => import('@v/system/iot/IotDevice.vue')
      }
      
    ]
  },
  {
    path: "*",
    component: () => import('@v/Error404.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
      return VueRouterPush.call(this, to).catch(err => err)
}
//返回路由
export default router
